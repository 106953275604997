.navbar-mobile {
    /*position: -webkit-sticky;*/
    /*position: sticky;*/
    /*top: 0;*/
    /*z-index: 100;*/
    /*width: 100%;*/
    height: 60px;
    border-top-left-radius: 4px;
	border-top-right-radius: 4px;
    background-color: #0e70ec;
    position: relative;
}

.navbar-mobile .stops-logo {
    width: 97px;
    height: 36px;
    object-fit: contain;
    padding-left: 16px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.navbar-mobile .profile-pic {
    width: 30px;
    height: 30px;
    object-fit: contain;
    right: 16px;
    top: 50%;
	border-radius: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.navbar-mobile .navbar-text {
    width: 100px;
    color: #ffffff;
    font-size: 12px;
    text-align: right;
    right: 54px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}