.download-banner {
    height: 80px;
    background-color: #ffffff;
    position: relative;
}

.banner-hidden {
    display: none;
}

.content-right {
}

.x-button {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 10px;
}

.stops-icon {
    width: 64px;
    height: 64px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 30px;
}

.banner-table {
    position: absolute;
    left: 100px;
    line-height: 15px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.stops-app-text {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
}

.orange-stars {
    width: 63px;
    height: 11px;
    object-fit: contain;
}

.stops-ltd-text {
    font-size: 12px;
    color: #6d7278;
}

.download-button {
    position: absolute;
    right: 10px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 87px;
    height: 31px;
    border-radius: 5px;
    border: solid 1px #dbdbdb;
    color: #0e70ec;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.51px;
    background-color: white;
    cursor: pointer;
}