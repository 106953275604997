.wiki-stops-nearby-mobile {
    /*height: 190px;*/
    width: 100%;
    /* padding-left: 16px; */
    /* padding-right: 16px; */
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
}

.wiki-stops-nearby-mobile .wiki-navigation {
    padding-bottom: 15px;
    position: relative;
	margin: 20px;
}

.wiki-stops-nearby-mobile .wiki-title {
    font-size: 16px;
    font-weight: bold;

	/* position: absolute; */
}

.wiki-stops-nearby-mobile .wiki-image {
    margin: 0 auto;
    width: 80%;
    /* height: 115px; */
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    cursor: pointer;
	/* box-shadow: 0px 6px 5px #ccc; */
	border-radius: 50%;
}

.wiki-stops-nearby-mobile .wiki-image-left {
    margin: 0 auto;
    width: 80%;
    /* height: 115px; */
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    cursor: pointer;
	box-shadow: 0px 6px 5px #ccc;
	border-radius: 50%;
	transform: translateX(-75px);
}


.wiki-stops-nearby-mobile .wiki-image-two {
	transform: translateX(-50px);
}

.wiki-stops-nearby-mobile .wiki-slide-title {
    margin: 0 auto;
    width: 90%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    padding-top: 10px;
	/* transform: translateX(10px); */
	text-align: center;
}

.wiki-stops-nearby-mobile .wiki-slide-title-two{
	transform: translateX(-50px);
}

.wiki-stops-nearby-mobile .wiki-slide-title-left {
    margin: 0 auto;
    width: 90%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    padding-top: 10px;
	transform: translateX(-75%);
}


.wiki-stops-nearby-mobile .wiki-container {
    position: relative;
}

.wiki-stops-nearby-mobile .wiki-logo {
    width: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
}

.wiki-stops-nearby-mobile .wiki-logo-left {
    width: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-173%) translateY(-50%);
    -ms-transform: translateX(-173%) translateY(-50%);
}

.wiki-stops-nearby-mobile .wiki-logo-two {
	transform: translateX(-80px) translateY(-20px);
}