.circle-view {
    position: relative;

    display: flex;
    margin-left: 20%;
    align-items:center;
    justify-content:center;
    object-fit: contain;

    /* width: 50%; */
    /* height: auto; */
    width: 150px;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 50%;

    cursor: pointer;

    /* Transitions */
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
}

.circle-view:hover {
    opacity: 0.75;
}

.circle-view:active {
    opacity: 0.9;
}

.stops-nearby-image-container {
    position: absolute;
    width: 150px; height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.stops-watermark {
    width: 40%;
    height: 40%;
    z-index: 2;
}

.preview-image {
    /* position: absolute; */
    border-radius: 50%;
    width: 150px; height: 150px;
}

.dim-overlay {
    position: absolute;
    border-radius: 50%;
    /* width: 150px; height: 150px; */
    width: 100%; height: 100%;
    left: 0; top: 0;
    background: rgba(0, 0, 0, 0.5);
}

/* Small View - For example: Collections list */

.circle-view-small {
    position: absolute;
    top: 20px;
    left: 79px;
	width: 80px;
    height: 80px;

    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 50%;

    cursor: pointer;

    /* Transitions */
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
}

.circle-view-small:hover {
    opacity: 0.75;
}

.circle-view-small:active {
    opacity: 0.9;
}

.stops-nearby-image-container-small {
    position: absolute;
    width: 79px; height: 79px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.stops-watermark-small {
    width: 25%;
    height: 25%;
    z-index: 2;
}

.preview-image-small {
    position: absolute;
    border-radius: 50%;
    width: 79px; height: 79px;
}

.dim-overlay-small {
    position: absolute;
    border-radius: 50%;
    /* width: 150px; height: 150px; */
    width: 100%; height: 100%;
    left: 0; top: 0;
    background: rgba(0, 0, 0, 0.5);
}

/* Mobile */

.circle-view-mobile {
    position: relative;
    
    display: flex;
    margin-left: 20%;
    align-items:center;
    justify-content:center;
    object-fit: contain;

    /* width: 50%; */
    /* height: auto; */
    width: 60%;
    height: 40%;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 50%;

    cursor: pointer;

    /* Transitions */
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
}

.stops-nearby-image-container-mobile {
    position: absolute;
    width: 100%; height: 100%;
    border-radius: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.stops-watermark-mobile {
    width: 20%;
    height: 20%;
    z-index: 2;
}

.preview-image-mobile {
    /* position: absolute; */
    border-radius: 50%;
    width: 100%; height: 100%;
}

.dim-overlay-mobile {
    position: absolute;
    border-radius: 50%;
    /* width: 150px; height: 150px; */
    width: 100%; height: 100%;
    left: 0; top: 0;
    background: rgba(0, 0, 0, 0.5);
}

/* Small Mobile - Mobile Collections List */

.circle-view-small-mobile {
    position: absolute;
    top: 20px;
    left: 55px;
	width: 50px;
    height: 50px;

    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 50%;

    cursor: pointer;

    /* Transitions */
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
}

.circle-view-small-mobile:hover {
    opacity: 0.75;
}

.circle-view-small:active {
    opacity: 0.9;
}

.stops-nearby-image-container-small-mobile {
    position: absolute;
    width: 50px; height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.stops-watermark-small-mobile {
    width: 25%;
    height: 25%;
    z-index: 2;
}

.preview-image-small-mobile {
    position: absolute;
    border-radius: 50%;
    width: 50px; height: 50px;
}

.dim-overlay-small-mobile {
    position: absolute;
    border-radius: 50%;
    /* width: 150px; height: 150px; */
    width: 100%; height: 100%;
    left: 0; top: 0;
    background: rgba(0, 0, 0, 0.5);
}