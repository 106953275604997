@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    src: url('./SF-Pro-Display-Medium.otf');
}

.comments-mobile-qa {
    /* height: 440px; */
    border-top: 1px solid #f1f1f1;
}

.comments-mobile-qa .comments-title {
    font-size: 14px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 16px;
}

.comments-mobile-qa .num-comments {
    font-size: 14px;
    text-align: right;
    color: #b2b2b2;
    font-weight: normal;
    padding-left: 2px;
}

.comments-mobile-qa .comments-container {
    font-size: 12px;
}

.comments-mobile-qa .comment-outer {
    padding-bottom: 10px;
}

.comments-mobile-qa .comment-container {
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    width: 85%;
    padding-left: 12px;
    margin: 0 auto;
}

.comments-mobile-qa .comment-info {
    padding-top: 11px;
    padding-bottom: 8px;
}


.comments-mobile-qa .mr-bubble-comment {
    height: 80px;
}

.comments-mobile-qa .comment-icon {
    width: 30px;
    height: 30px;
    float: left;
    padding-top: 2px;
}

.comments-mobile-qa .comment-name-date {
    padding-left: 40px;
}

.comments-mobile-qa .comment-name {
    font-weight: bold;
}

.comments-mobile-qa .comment-date {
    color: #979797;
}

.comments-mobile-qa .comment {
    clear: both;
    line-height: 1.33;
    width: 95%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.comments-mobile-qa .mr-bubble {
    border-radius: 50%;
}

.comments-mobile-qa .show-more {
    width: 89%;
    margin: 0 auto;
    padding-top: 5px;
}

.comments-mobile-qa .show-more-text {
    height: 16px;
    font-family: 'SF-Pro';
    font-size: 14px;
    text-align: right;
    color: #b2b2b2;
}

.comments-mobile-qa .arrow-down {
    width: 12px;
    height: 7px;
    padding-left: 3px;
    padding-bottom: 1px;
}