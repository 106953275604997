.google-articles-nearby-desktop {
    width: 900px;
    height: 315px;
    border-bottom: 1px solid #d2d3d5;
    background-color: white;
    margin: 0 auto;
	margin-bottom: 20px;
}


.google-articles-nearby-desktop .google-container {
    position: relative;
}


.google-articles-nearby-desktop .google-navigation {
    padding-bottom: 15px;
    position: relative;
	margin: 20px;
}

.google-articles-nearby-desktop .google-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;

	
}


.google-articles-nearby-desktop .go-title{
	font-size: 16px;
    font-weight: 600;
 
    letter-spacing: -0.46px;
    color: #2c2c2c;
  
    margin: 0px 0px 0px 57.5px;
	margin-top:20px;
	
}

.google-articles-nearby-desktop .go-title-left{
	font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    margin: 0px 0px 0px 57.5px;
	transform: translateX(-300px);
	
}


.google-articles-nearby-desktop .go-logo {
	width:50px;
	position: absolute;
    top: 67.5px;
    left: 125px;
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
	
}

.google-articles-nearby-desktop .go-logo-left {
	width:50px;
	position: absolute;
    top: 67.5px;
    left: 125px;
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
	transform: translateX(-55px);
	
}


.google-articles-nearby-desktop .go-container{
    width: 185px;
    height: 185px;
    
    border-radius: 50%;
	background: #D3D3D3 no-repeat center;
	background-size: 200%;
	margin: 0 auto;
	
}

.google-articles-nearby-desktop .go-container-left{
    width: 185px;
    height: 185px;
    
    border-radius: 50%;
	background: #D3D3D3 no-repeat center;
	background-size: 200%;
	margin: 0 auto;
	transform: translateX(-300px);
	
}
	
.slick-list{
	/* overflow: auto; */
}


