.art-board-stop-desktop {
    background-color: white;
    width: 900px;
    margin: 0 auto;
    /*border-top-left-radius: 12px;*/
    /*border-top-right-radius: 12px;*/
}

.art-board-stop-desktop .container {
    padding: 30px;
}

.art-board-stop-desktop .title {
    height: auto;
    font-size: 28px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    padding-bottom: 5px;
}

.art-board-stop-desktop .date {
    width: 205px;
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6d7278;
}


.art-board-stop-desktop .info-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: #b2b2b2;
    padding-bottom: 4px;
}


.art-board-stop-desktop .info-text {
    font-size: 18px;
    line-height: 1.14;
    color: #0b7ffd;
    padding-bottom: 20px;
}

.art-board-stop-desktop .info-regular {
    font-size: 18px;
    line-height: 1.14;
    
    padding-bottom: 20px;
	    margin-left: -3px;
}

.art-board-stop-desktop .info-text > a{
    font-size: 18px;
    line-height: 1.14;
    color: #0b7ffd;
    padding-bottom: 20px;
}


.art-board-stop-desktop .working-hours {
    font-size: 18px;
    line-height: 1.78;
    color: #333d48;
    margin-left: -3px;
}

.art-board-stop-desktop .description {
    /*width: 640px;*/
    /* min-height: 144px; */
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2c2c2c;
    white-space: pre-line;
}

.art-board-stop-desktop .hashtag {
    width: 147px;
    height: 26px;
    object-fit: contain;
    border-radius: 6px;
    background-color: #0b7ffd;
    font-size: 16px;
    color: #ffffff;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 10px;
}


.art-board-stop-desktop .location-link a {
    color: blue;
  }

  .art-board-stop-desktop .business-closed{
      color: red;
  }