@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    src: url('./SF-Pro-Display-Medium.otf');
}

.comments-desktop-qa {
    width: 900px;
    /* height: 540px; */
    
    margin: 0 auto;
    background-color: white;


}

.comments-desktop-qa .comments-title {
    font-size: 18px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
}

.comments-desktop-qa .num-comments {
    font-size: 18px;
    text-align: right;
    color: #b2b2b2;
    font-weight: normal;
    padding-left: 2px;
}

.comments-desktop-qa .comments-container {
    font-size: 16px;
}

.comments-desktop-qa .comment-outer {
    padding-bottom: 15px;
}

.comments-desktop-qa .comment-container {
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    width: 800px;
    padding-left: 30px;
    margin: 0 auto;
}

.comments-desktop-qa .comment-info {
    padding-top: 11px;
    padding-bottom: 8px;
}



.comments-desktop-qa .comment-icon {
    width: 30px;
    height: 30px;
    float: left;
    padding-top: 2px;
}

.comments-desktop-qa .comment-name-date {
    padding-left: 40px;
}

.comments-desktop-qa .comment-name {
    font-weight: bold;
}

.comments-desktop-qa .comment-date {
    color: #979797;
}

.comments-desktop-qa .comment {
    /*clear: both; */
    line-height: 1.33;
    /* width: 740px; */
    height: auto;
    /* float: left; */
    /* min-height: 144px; */
    /* overflow-x: hidden; */
    /* text-overflow: ellipsis; */
    /* white-space: nowrap; */
    /* word-wrap: break-word; */
    margin-bottom: 16px;
   
}

.comments-desktop-qa .clearfix {
    clear: both;
}

.comments-desktop-qa .mr-bubble {
    border-radius: 50%;
}

.comments-desktop-qa .show-more {
    /*width: 340px;*/
    /*margin: 0 auto;*/
    padding-right: 30px;
    padding-top: 5px;
}

.comments-desktop-qa .show-more-text {
    font-family: 'SF-Pro';
    font-size: 16px;
    text-align: right;
    color: #b2b2b2;
}

.comments-desktop-qa .arrow-down {
    width: 12px;
    height: 7px;
    padding-left: 3px;
    padding-bottom: 1px;
}