.travel-dashboard-mobile .travel-info {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
    line-height: 1.5;
}

.travel-dashboard-mobile .dashboard-title {
    font-size: 20px;
    font-weight: bold;
}

.travel-dashboard-mobile .number-stops {
    font-size: 16px;
    color: #0e70ec;
}

.travel-dashboard-mobile .distance-time {
    font-size: 16px;
    color: #6d7278;
    padding-left: 16px;
}

.travel-dashboard-mobile .travel-map {
    width: 100%
}

.travel-dashboard-mobile .photo-info{
    text-align: center;
    font-size: 18px;
    color: #6d7278;
}

.travel-dashboard-mobile .pin-icon-container{
	
	    position: relative;
        display: inline-block; /* Make the width of box same as image */
		left: -240px;
}

.travel-dashboard-mobile .pin-icon {
    width: 30px;
    /* height: 30px; */
    position: absolute;
    top: 28px;
    left: 16px;
}

.travel-dashboard-mobile .pin-num {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;

    top: 33px;
    left: 27px;
	
    position: absolute;
        z-index: 999;
		/*
        margin: 0 auto;
        left: 0;
        right: 0;
		
        top: 40%; 
        text-align: center;
        width: 60%; 
		*/
   
}


.travel-dashboard-mobile .photo-title{
	text-align: left;
	margin-left: 60px;
	margin-top: 30px;
	
	
}