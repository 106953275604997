.app-collections-desktop {
  /*background-repeat: no-repeat;*/
  font-family: 'Open Sans', sans-serif;
}

.app-collections-desktop .wrapper {
}

.app-collections-desktop .outer {
  /*background-image: url("stops-city-background.jpg");*/
}

.app-collections-desktop .inner {
  /*background: rgba(255,255,255,0.8);*/
  /*background: rgba(59,124,242,0.7);*/
}

.app-collections-desktop .top-components {
}

.app-collections-desktop .bottom-components {
  width: 900px;
  margin: 0 auto;
  /*border-radius: 15px;*/
  /*padding-top: 30px;*/
  border-left: 1px solid #d2d3d5;
  border-right: 1px solid #d2d3d5;
}

/*.app-collections-desktop .box-shadow {*/
/*  !*box-shadow: 5px 5px 10px*!*/
/*}*/

.app-collections-desktop .show-background-color {
  /*background: rgba(59,124,242,1);*/
  background-color: white;
  padding-bottom: 30px;
}

/*slider styles*/

.app-collections-desktop .arrow {
  width: 30px;
  height: 18px;
  object-fit: contain;
  position: absolute;
}

.app-collections-desktop .next {
  right: 30px;
  cursor: pointer;
}

.app-collections-desktop .prev {
  right: 74px;
  cursor: pointer;
}

a {
  color: #ffffff;
}

a:visited {
  color: #ffffff;
}