.yelps-nearby-mobile {
    /* padding-left: 16px; */
    /* padding-right: 16px; */
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px
}

.yelps-nearby-mobile .yelps-navigation {
    padding-bottom: 15px;
    position: relative;
	margin-bottom:20px;
}

.yelps-nearby-mobile .yelps-nearby-title {
    font-size: 16px;
    font-weight: bold;
	margin-left: 20px;
}

.yelps-nearby-mobile .yelps-title {
    width: 95px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
}

.yelps-nearby-mobile .yelp-container {
    position: relative;
}

.yelps-nearby-mobile .yelp-image {
    margin: 0 auto;
    width: 80%;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
	box-shadow: 0px 6px 5px #ccc;
}

.yelps-nearby-mobile .yelp-image-left {
    margin: 0 auto;
    width: 80%;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
	box-shadow: 0px 6px 5px #ccc;
	transform: translateX(-75px);
}

.yelps-nearby-mobile .yelp-image:hover {
    margin: 0 auto;
    width: 80%;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
	
}


.yelps-nearby-mobile .yelp-logo {
    width: 32px;
    height: 43px;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-120%);
    -ms-transform: translateX(-50%) translateY(-120%);
}
.yelps-nearby-mobile .yelp-logo-left {
    width: 32px;
    height: 43px;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-280%) translateY(-120%);
    -ms-transform: translateX(-280%) translateY(-120%);
}

.yelps-nearby-mobile .yelp-slide-title {
    margin: 0 auto;
    /*width: 80%;*/
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
}

.yelps-nearby-mobile .rating-info {
    margin-top: -3px;
    margin-bottom: -3px;
    margin-left: -3px;
}

.yelps-nearby-mobile .star-rating {
    width: 10px;
    font-size: 12px;
    color: CornFlowerBlue;
}

.yelps-nearby-mobile .stars {
    height: 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.yelps-nearby-mobile .num-ratings {
    width: 37px;
    height: 18px;
    font-size: 12px;
    color: #979797;
}

.yelps-nearby-mobile .yelp-slide-text {
    margin: 0 auto;
    width: 80%;
    padding-top: 15px;
}

.yelps-nearby-mobile .yelp-slide-text-left {
    margin: 0 auto;
    width: 80%;
    padding-top: 15px;
	transform: translateX(-80%);
    -ms-transform: translateX(-80%);
}

.yelps-nearby-mobile .yelp-info {
    margin: 0 auto;
    width: 105%;
    font-size: 12px;
    color: #979797;
}


img {  
    position: relative;
  }
  
  /* style this to fit your needs */
  /* and remove [alt] to apply to all images*/
  img[alt]:after {  
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-family: 'Helvetica';
    font-weight: 300;
    line-height: 2;  
    text-align: center;
    content: attr(alt);
  }