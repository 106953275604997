.map-stop-desktop {
    /*width: 700px;*/
    /*height: 144px;*/
    height: 185px;
}

.map-stop-desktop .map-container {
    position: relative;
}

.map-stop-desktop .map-image {
    width: 900px;
    margin: 0 auto;
}

.map-stop-desktop .pin {
    width: 45px;
    height: 56px;
    object-fit: contain;
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}