.meetups-nearby-desktop {
    width: 900px;
    height: 370px;
    border-bottom: 1px solid #d2d3d5;
    background-color: white;
    padding-top: 30px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.meetups-nearby-desktop .meetup-navigation {
    padding-bottom: 15px;
    position: relative;
	margin: 20px;
}

.meetups-nearby-desktop .slick-track
{
	width: 38000px !important;
}

.meetups-nearby-desktop .meetup-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;

}

.meetups-nearby-desktop .meetup-image {
    margin: 0 auto;
    width: 180px;
    height: 180px;
    /* object-fit: contain; */
    object-fit: fill;
    border-radius: 4px;
    background-color: #f0f1f3;
    border-radius: 50%;
}


.meetups-nearby-desktop .meetup-image-left {
    margin: 0 auto;
    width: 180px;
    height: 180px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    border-radius: 50%;
	/* transform: translateX(-300px);	 */
}


.meetups-nearby-desktop .meetup-slide-title {
    /* margin-left: 30px;  */
    width: 180px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    padding-top: 15px;
    transform: translateX(45px);
}

.meetups-nearby-desktop .meetup-container {
    position: relative;
	width: 200px;
    margin-left: 20px; 
}

.meetups-nearby-desktop .meetup-logo {
    width: 85px;
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
}

.meetups-nearby-desktop .meetup-logo-left {
    width: 85px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-400%) translateY(-50%);
    -ms-transform: translateX(-400%) translateY(-50%);
	
}

.meetups-nearby-desktop .meetup-slide-text {
	
}

.meetups-nearby-desktop .meetup-slide-text-left{
	transform: translateX(-300px);	
}