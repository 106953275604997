.wiki-stops-nearby-desktop {
    /* width: 900px; */
    width: 100%;
    height: 330px;
    border-bottom: 1px solid #d2d3d5;
    background-color: white;
    padding-top: 30px;
    margin: 0 auto;
	margin-bottom: 20px;
}

.wiki-stops-nearby-desktop .wiki-navigation {
    padding-bottom: 15px;
    position: relative;
	margin: 20px;
}

.wiki-stops-nearby-desktop .slick-track
{
	width: 38000px !important;
}

.slick-prev:before,
.slick-next:before {
  color: #5B5B5B !important;
}

.wiki-stops-nearby-desktop .wiki-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;

}

.wiki-stops-nearby-desktop .wiki-image {
    margin: 0 auto;
    width: 90%;
    height: 90%;
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    border-radius: 50%;
}


.wiki-stops-nearby-desktop .wiki-image-left {
    margin: 0 auto;
    width: 90%;
    height: 90%;
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    border-radius: 50%;
	/* transform: translateX(-300px);	 */
}


.wiki-stops-nearby-desktop .wiki-slide-title {
    /*
    margin-left: 30px; 
    width: 180px;
    */
    margin: 0 auto;
    width: 50%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    padding-top: 15px;
    /* transform: translateX(45px); */
    text-align: center;

    /* Limit 2 lines title */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    text-align: center;
}

.wiki-stops-nearby-desktop .wiki-container {
    position: relative;
	width: 200px;
    /* margin-left: 20px;  */
    margin: 0 auto;
}

.wiki-stops-nearby-desktop .wiki-logo {
    width: 85px;
    
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
}

.wiki-stops-nearby-desktop .wiki-logo-left {
    width: 85px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-400%) translateY(-50%);
    -ms-transform: translateX(-400%) translateY(-50%);
	
}

.wiki-stops-nearby-desktop .wiki-slide-text{
	
}

.wiki-stops-nearby-desktop .wiki-slide-text-left{
	transform: translateX(-300px);	
}

