.meetups-nearby-mobile {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d2d3d5;
}

.meetups-nearby-mobile .meetups-navigation {
    padding-bottom: 15px;
    position: relative;
	margin-bottom:20px;
}

.meetups-nearby-mobile .meetups-nearby-title {
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;
    max-lines: 3;
}

.meetups-nearby-mobile .meetups-title {
    width: 95px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
}

.meetups-nearby-mobile .meetup-container {
    position: relative;
}

.meetups-nearby-mobile .meetup-image {
    margin: 0 auto;
    height: 90px;
    width: 80%;
    /* object-fit: contain; */
    object-fit: fill;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;	
	box-shadow: 0px 6px 5px #ccc;
}

.meetups-nearby-mobile .meetup-image-left {
    margin: 0 auto;
    width: 80%;
    object-fit: contain;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;	
	box-shadow: 0px 6px 5px #ccc;
	transform: translateX(-110px);
}

.meetups-nearby-mobile .meetup-image:hover {
    margin: 0 auto;
    width: 80%;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
}

.meetups-nearby-mobile .meetup-image-two {
	transform: translateX(-50px);
}

.meetups-nearby-mobile .meetup-logo {
    width: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
}

.meetups-nearby-mobile .meetup-logo-left {
    width: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-173%) translateY(-50%);
    -ms-transform: translateX(-173%) translateY(-50%);
}

.meetups-nearby-mobile .meetup-slide-title {
    margin: 0 auto;
    /*width: 80%;*/
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
}

.meetups-nearby-mobile .meetup-slide-text {
    margin: 0 auto;
    width: 80%;
    padding-top: 15px;
}

.meetups-nearby-mobile .meetup-slide-text-left {
    margin: 0 auto;
    width: 80%;
    padding-top: 15px;
	transform: translateX(-80%);
    -ms-transform: translateX(-80%);
}

.meetups-nearby-mobile .meetup-info {
    margin: 0 auto;
    width: 105%;
    font-size: 12px;
    color: #979797;
}