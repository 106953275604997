.photo-info-stop-mobile {
    /*display: flex;*/
    /*justify-content: center;*/
    /*height: 235px;*/
}

.photo-info-stop-mobile .photo-info-container {
    padding-top: 24px;
    pading-bottom: 30px;
    padding-right: 16px;
    padding-left: 16px;
	/*
    height: 300px;
	*/
    position: relative;
}

.photo-info-stop-mobile .title {
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.21;
    letter-spacing: normal;
    padding-bottom: 4px;
}

.photo-info-stop-mobile .date {
    font-size: 14px;
    color: #6d7278;
}

.photo-info-stop-mobile .description {
    /*height: 160px;*/
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #2c2c2c;
    white-space: pre-line;
}

.photo-info-stop-mobile .hashtag-table {
    position: absolute;
    bottom: 15px;
}

.photo-info-stop-mobile .hashtag-div 
{
	display:inline-table;
	margin:15px 0px 16px 0px;
}		
	

.photo-info-stop-mobile .hashtag-item {
    padding-right: 8px;
}

.photo-info-stop-mobile .hashtag {
    object-fit: contain;
    border-radius: 6px;
    background-color: #0b7ffd;
    font-size: 14px;
    color: #ffffff;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 10px;
    padding-left: 10px;
	overflow: hidden;
    white-space: nowrap;
}


.photo-info-stop-mobile .location-link a {
    color: blue;
  }
  
  
  
.photo-info-stop-mobile .info-title {
    height: 14px;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: 0.3px;
    color: #b2b2b2;
    padding-bottom: 5px;
}  

.photo-info-stop-mobile .info-text {
    height: 16px;
    font-size: 14px;
    line-height: 1.14;
    color: #0b7ffd;
    padding-bottom: 15px;
}

.photo-info-stop-mobile .info-regular {
    height: 16px;
    font-size: 14px;
    line-height: 1.14;
    /* color: #0b7ffd; */
    padding-bottom: 15px;
}

.photo-info-stop-mobile .info-text > a{
    height: 16px;
    font-size: 14px;
    line-height: 1.14;
    color: #0b7ffd;
    padding-bottom: 15px;
}

.photo-info-stop-mobile .working-hours-title {
    height: 14px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: #b2b2b2;
    padding-bottom: 3px;
}

.photo-info-stop-mobile .open-now-container {
    position: relative;
    padding-bottom: 5px;
}

.photo-info-stop-mobile .open-now-text {
    width: 75px;
    height: 16px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.14;
    color: #333d48;
}

.photo-info-stop-mobile .down-arrow {
    top: 47%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    padding-left: 2px;
}

.photo-info-stop-mobile .working-hours {
    font-size: 14px;
    line-height: 1.45;
    color: #333d48;
    margin-left: -3px;
}

.photo-info-stop-mobile .working-hours > tr > td {
    padding-right: 43px;
}
.photo-info-stop-mobile .business-info{
	margin-left: 30px;
}

.photo-info-stop-mobile .business-closed{
	color: red;
}