@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    src: url('./SF-Pro-Display-Medium.otf');
}

.pin-info-desktop {
    width: 900px;
    background-color: white;
    margin: 0 auto;
}

.pin-info-desktop .pins-container {
    font-size: 14px;
}

.pin-info-desktop .pin-outer {
    /*padding-bottom: 10px;*/
}

.pin-info-desktop .pin-1 {
    /* height: 178px; */
}

.pin-info-desktop .pin-2 {
    /* height: 158px; */
}

.pin-info-desktop .pin-3 {
    /* height: 178px; */
}

.pin-info-desktop .pin-4 {
    /* height: 178px; */
}


.pin-info-desktop .pin-header {
    position: relative;
}

.pin-info-desktop .pin-info-icon {
    width: 32px;
    /* height: 40px; */
    position: absolute;
    top: 40px;
    left: 30px;
}

.pin-info-desktop .pin-num {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    top: 45px;
    left: 35px;
}


.pin-info-desktop .pin-num-1 {
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    top: 45px;
    left: 40px;
}

.pin-info-desktop .location-pic {
    
    /* height: 80px; */
    position: absolute;
    top: 20px;
    left: 79px;
	width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.pin-info-desktop .pin-header-info {
    position: absolute;
    top: 20px;
    left: 175px;
    line-height: 1.2;
}

.pin-info-desktop .pin-name {
    font-size: 22px;
    line-height: 1.38;
    font-weight: bold;
    padding-bottom: 3px;
}

.pin-info-desktop .more-information {
    font-family: "SF-Pro";
    font-size: 14px;
    line-height: 1.17;
    color: #a9abae;
    padding-bottom: 2px;
}

.pin-info-desktop .pin-address {
    font-family: "SF-Pro";
    font-size: 14px;
    line-height: 1.17;
    color: #a9abae;
}

.pin-info-desktop .stars-table {
}

.pin-info-desktop .star-num {
    font-size: 13px;
    line-height: 1.17;
    color: #0b7ffd;
    padding-right: 2px;
}

.pin-info-desktop .cell-width {
    width: 15px;
}

.pin-info-desktop .blue-stars {
    width: 17px;
}

.pin-info-desktop .num-ratings {
    font-size: 14px;
    line-height: 1.17;
    color: #b2b2b2;
}

.pin-info-desktop .yelp-stars {
    width: 85px;
}

.pin-info-desktop .num-reviews {
    font-family: "SF-Pro";
    font-size: 14px;
    line-height: 1.17;
    color: #a9abae;
    padding-left: 5px;
}

.pin-info-desktop .pin-description {
    width: 840px;
    /* height: 80px; */
    font-size: 14px;
    line-height: 1.43;
    color: #2c2c2c;
    padding-top: 112px;
    margin: 40px 0px 26px 30px;
}

.pin-info-desktop .pin-container {
    object-fit: contain;
    border-bottom: solid 1px #e5e5e5;
}