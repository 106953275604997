.location-info-pixel-desktop {
    background-color: white;
    width: 900px;
    margin: 0 auto;
}

/*
.location-widget-container-mobile {
    width: 50%;
    height: 100px;
    display: table-cell; 

    border-radius: 12px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    margin: 0 auto;
    margin-top: 25px;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}
*/

.location-widget-container-mobile {
    width: 90%;
    height: 55%;

    border-radius: 12px;
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;

    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 25px;
    padding-top: 15px;
    padding-bottom: 15px;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;

    /* display: grid; */
    /* grid-template-columns: auto auto auto auto; */
    /* grid-gap: 10px; */
    /* background-color: #2196F3; */
    /* padding: 10px; */
}

.location-widget-container-mobile .map-container {
    position: relative;
}

.location-widget-container-mobile .location-pin-wrap {
    position: absolute;
    filter: drop-shadow(0px 0px 3px rgba(50, 50, 0, 0.25));
    
    left: 0; 
    right: 0;
    top: 0;
    bottom: 0;
    
    width: 100%;
    height: 100%;

    /* background-color: red; */
}

.location-widget-container-mobile .location-pin {
    width: 50px;
    height: 50px;
    margin: 0% auto;
    
    left: 50%;
    right: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    mask-image: url(../LargeCarousel/cube-image_mask_w_border.svg);
    
    /* mask-border: url(./cube-image_mask_border.svg); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;

    border-radius: 3px;
    -webkit-border-radius: 3px;
}

/*
.map-stop-desktop .map-image {
    width: 900px;
    margin: 0 auto;
}
*/

/*
.location-widget-container-mobile .location-pin {
    width: 45px;
    height: 56px;
    object-fit: contain;
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    border: solid 1px #f3f3f3;
}
*/

/*
.large-carousel-cube-mobile .mask-cube-image {
    mask-image: url(./cube-image_mask_w_border.svg);
    /* mask-border: url(./cube-image_mask_border.svg); *\/
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
}
*/

/*
.location-widget-container-mobile > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}
*/

.location-widget-container-mobile .map-image {
    width: 90%;
    height: 35%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    /* margin-left: 10px; */
    background-color: #c4c4c4;
    border-radius: 12px;
    border: none;
    /* margin-top: 5%; */

    /* display: table-cell;  */
    /* vertical-align: middle; */
    /* float: left; */
    object-fit: cover;
}

.location-widget-container-mobile .texts-col {
    display: table-cell;
    /* background-color: teal; */
}

.location-widget-container-mobile .texts-col .map-title-text {
    display: block; 
    /* width: 81px; */
    /* height: 20px; */
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a6afaf;
    margin-left: 15px;
    margin-top: 5px;

    /* background-color: blue; */
}

.location-widget-container-mobile .texts-col .map-address-text {
    display: block; 
    float: left;
    margin-left: 15px;
    margin-top: 10px;

    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #c4c4c4;

    /* Limit Text 2 lines */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

/*////////////////*/

.location-info-pixel-desktop .location {
    height: 55px;
    object-fit: contain;
    position: relative;
    border-bottom: 1px solid #d2d3d5;
}

.location-info-pixel-desktop .pin-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-pixel-desktop .location-row {
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2c2c2c;
    padding-left: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-pixel-desktop .location-text {
    padding-left: 45px;
}

.location-info-pixel-desktop .location-link {
    color: black;
}

.location-info-pixel-desktop .altitude {
    height: 55px;
    object-fit: contain;
    position: relative;
    border-bottom: 1px solid #d2d3d5;
}

.location-info-pixel-desktop .altitude-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-pixel-desktop .altitude-row {
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2c2c2c;
    padding-left: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-pixel-desktop .altitude-text {
    padding-left: 45px;
}

.location-info-pixel-desktop .sea-level {
    color: #b2b2b2;
}