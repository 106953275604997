/*.bottom-nav {*/
/*     overflow-y: scroll;*/
/*     -webkit-overflow-scrolling: touch;*/
/*    !*position: fixed;*!*/
/*    position: sticky;*/
/*    position: -webkit-sticky;*/
/*    bottom: 0px;*/
/*    width: 100%;*/
/*    !*height: 100%;*!*/
/* }*/

/*.bottom-nav {*/
/*    position: fixed;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    width: 18rem;*/
/*    text-align: left;*/
/*    height: 100vh;*/
/*}*/