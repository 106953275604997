.location-info-stop-mobile {
    width: 100%;
}

.location-info-stop-mobile .map {
    width: 100%;
	/*
	height: 240px;
    height: 144px;
	*/
    object-fit: contain;
    position: relative;
}

.location-info-stop-mobile .map-image {
    width: 100%;
	
	/*
    height: 240px;
    height: 120px !important;
	*/
    /*object-fit: cover;*/
}

.location-info-stop-mobile .pin {
    width: 40px;
    height: 51px;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.location-info-stop-mobile .location {
    height: 80px;
    object-fit: contain;
    position: relative;
    border-bottom: 1px solid #f1f1f1;
}

.location-info-stop-mobile .pin-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    top: 50%;
    -ms-transform: translateY(-60%);
    transform: translateY(-60%);
    position: absolute;
}

.location-info-stop-mobile .location-row {
    height: auto;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2c2c2c;
    padding-left: 20px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-stop-mobile .location-text {
    font-size: 14px; 
    /* text-overflow: ellipsis; */
    position: relative;
    left: 35px; 
    /* border: 1px solid white; */
    /* padding: 3px; */
    vertical-align: middle;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%);
}

.location-info-stop-mobile .location-link {
    color: black;
    border: 1px solid white;
}

.location-info-stop-mobile .altitude {
    height: 55px;
    object-fit: contain;
    position: relative;
    border-bottom: 1px solid #f1f1f1;
}

.location-info-stop-mobile .altitude-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-stop-mobile .altitude-row {
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2c2c2c;
    padding-left: 20px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-stop-mobile .altitude-text {
    padding-left: 35px;
    font-size: 14px;
}

.location-info-stop-mobile .sea-level {
    color: #b2b2b2;
}

.location-info-stop-mobile .address-info{
    margin-right: 20px;
    padding-right: 20px;
}