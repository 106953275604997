.attachments-desktop {
    background-color: white;
    /* height: 310px; */
    padding-top: 30px;
    border-bottom: 1px solid #d2d3d5;
	padding-bottom:20px;
    width: 900px;
    margin: 0 auto;
}

.attachments-desktop .slider {
}

.attachments-desktop .attachments-navigation {
    padding-bottom: 15px;
    position: relative;
}

.attachments-desktop .attachments-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    padding-left: 30px;
}

.attachments-desktop .attachment-item {
    
	background-color: #D3D3D3;
	
	height:100px;
	width:100px;
	display: flex;
	justify-content: center;
	align-items: center;
    
	color: #fff;
	font-size: 2em;
	font-weight: bold;
	font-family: Helvetica;
	text-align: center;
	margin-left: 40px;

	/* text-shadow:  */
    /* 0 1px 0 #ccc,  */
    /* 0 2px 0 #c9c9c9,  */
    /* 0 3px 0 #bbb,  */
    /* 0 4px 0 #b9b9b9,  */
    /* 0 5px 0 #aaa,  */
    /* 0 6px 1px rgba(0,0,0,.1),  */
    /* 0 0 5px rgba(0,0,0,.1),  */
    /* 0 1px 3px rgba(0,0,0,.3),  */
    /* 0 3px 5px rgba(0,0,0,.2),  */
    /* 0 5px 10px rgba(0,0,0,.25),  */
    /* 0 10px 10px rgba(0,0,0,.2),  */
    /* 0 20px 20px rgba(0,0,0,.15); */
	
}


.attachments-desktop .attachment-item-left {
   transform: translate(-200%, 0%);	
}

.attachments-desktop .attachment-item-two{
	transform: translateX(-50px);
}

.attachments-desktop .attachment-slide-title-two{
	transform: translateX(-90px);
	
	
}

.attachments-desktop .file-size-two{
	transform: translateX(-90px);
}


.attachments-desktop .attachement-link{
	text-decoration: none;
}



.attachments-desktop .attachment-item-2 {
	height:100px;
	width:100px;
	display: flex;
	justify-content: center;
	align-items: center;
    transform: translate(-270%, 0%);
	
	color: #131313;
    background-color: #e7e5e4;
    letter-spacing: .15em;
	font-size: 2em;
    text-shadow: 
      1px -1px 0 #767676, 
      -1px 2px 1px #737272, 
      -2px 4px 1px #767474, 
      -3px 6px 1px #787777, 
      -4px 8px 1px #7b7a7a, 
      -5px 10px 1px #7f7d7d, 
      -6px 12px 1px #828181, 
      -7px 14px 1px #868585, 
      -8px 16px 1px #8b8a89, 
      -9px 18px 1px #8f8e8d, 
      -10px 20px 1px #949392, 
      -11px 22px 1px #999897, 
      -12px 24px 1px #9e9c9c, 
      -13px 26px 1px #a3a1a1, 
      -14px 28px 1px #a8a6a6, 
      -15px 30px 1px #adabab, 
      -16px 32px 1px #b2b1b0, 
      -17px 34px 1px #b7b6b5, 
      -18px 36px 1px #bcbbba, 
      -19px 38px 1px #c1bfbf, 
      -20px 40px 1px #c6c4c4, 
      -21px 42px 1px #cbc9c8, 
      -22px 44px 1px #cfcdcd, 
      -23px 46px 1px #d4d2d1, 
      -24px 48px 1px #d8d6d5, 
      -25px 50px 1px #dbdad9, 
      -26px 52px 1px #dfdddc, 
      -27px 54px 1px #e2e0df, 
      -28px 56px 1px #e4e3e2;
}

.attachments-desktop .attachment-image {
    margin: 0 auto;
    /*width: 120px;*/
    /*height: 90px;*/
    width: 240px;
    /*width: 90%;*/
    height: 180px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    cursor: pointer;
}

.attachments-desktop .attachment-slide-title {
    margin: 0 auto;
    width: 240px;
    height: 18px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
}

.attachments-desktop .attachment-slide-title-left
{
		transform: translateX(60px);
}

.attachments-desktop .file-size-left{
		transform: translateX(60px);
}


.attachments-desktop .attachment-slide-text {
    padding-top: 15px;
}



.attachments-desktop .attachment-slide-text-left {
    transform: translate(-100%, 0%);
}

.attachments-desktop .file-size {
    margin: 0 auto;
    width: 240px;
    height: 18px;
    font-size: 18px;
    line-height: 1.13;
    color: #979797;
    padding-top: 5px;
}