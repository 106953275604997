.features-mobile {
    background-image: url("stops-background.jpg");
    background-size: 100%;
   height: 380px;
    object-fit: contain; 
	/* background-repeat: no-repeat; */
    display: inline-block; 
    

}


@media screen and (max-width: 415px){
    .features-mobile {
        height: 385px;
        
    }
}


@media screen and (max-width: 412px){
    .features-mobile {
        height: 380px;
        
    }
}


@media screen and (max-width: 376px){
    .features-mobile {
        height: 350px;
        
    }
}

@media screen and (max-width: 361px){
    .features-mobile {
        height: 350px;
       
    }
}

@media screen and (max-width: 321px){
    .features-mobile {
        height: 310px;
        
    }
}


.features-mobile .with-stops {
    height: 21px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.17;
    text-align: center;
    color: #ffffff;
    padding-top: 24px;
    padding-bottom: 5px;
}

.features-mobile .features-list {
    /* width: 328px; */
    /* height: 152px; */
    font-size: 12px;
    line-height: 1.33;
    color: #ffffff;
    margin: 0 auto;
    padding-left: 10px;
}

.features-mobile .list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

.features-mobile .feat {
    padding-left: 25px;
    text-indent: -25px;
    padding-bottom: 10px;
}

.features-mobile .feat:before {
    content: "•";
    padding-right: 20px;
}