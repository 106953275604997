@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    src: url('./SF-Pro-Display-Medium.otf');
}

.weather-desktop {
    background-color: white;
    height: 200px;
    border-bottom: 1px solid #f1f1f1;
    width: 900px;
    margin: 0 auto;
}

.weather-desktop .weather-info {
    padding-left: 30px;
    padding-top: 30px;
}

.weather-desktop .weather-title {
    width: 86px;
    height: 28px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
}

.weather-desktop .weather-description {
    position: relative;
    width: 700px;
    padding-top: 15px;
}

/*.description-text-wrapper {*/
/*}*/

.weather-desktop .now {
    width: 40px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.44px;
    margin-bottom: 10px;
}

.weather-desktop .description-text {
    width: 115px;
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.35px;
    top: 50%;
}

.weather-desktop .weather-icon {
    width: 49px;
    height: 47px;
    object-fit: contain;
    left: 141px;
    bottom: -10px;
    /*top: 50%;*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    position: absolute;
}

.weather-desktop .temperature {
    position: relative;
}

.weather-desktop .temperature-value {
    font-family: "SF-Pro";
    width: 67px;
    height: 43px;
    font-size: 36px;
    /*font-weight: 500;*/
    letter-spacing: -1.16px;
    text-align: center;
    left: 184px;
    bottom: -13px;
    /*top: 50%;*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    position: absolute;
}

.weather-desktop .temperature-value-f {
    font-family: "SF-Pro";
    width: 67px;
    height: 43px;
    font-size: 36px;
    /*font-weight: 500;*/
    letter-spacing: -1.16px;
    text-align: center;
    left: 272px;
    bottom: -13px;
    /*top: 50%;*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    position: absolute;
}

.weather-desktop .degrees {
    width: 29px;
    height: 18px;
    object-fit: contain;
    position: absolute;
    left: 239px;
    bottom: 10px;
}

.weather-desktop .degrees-f {
    width: 29px;
    height: 18px;
    object-fit: contain;
    position: absolute;
    left: 338px;
    bottom: 10px;
}