.art-board-cube-mobile {
    background-color: white;
    /* width: 900px; */
    width: 90%;
    margin: 0 auto;
    
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.attachments-widget-container-mobile {
    display: flex;
    justify-content: center;
}

.attachments-widget-container-mobile .row {
    display: grid;
    grid-row: 3 / 5;
    grid-column: 3 / 3;
}

.attachments-widget-container-mobile .row .number-text {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #5B5B5B;
}

.attachments-widget-container-mobile .row .item-text {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-weight: 400;
    color: #5B5B5B;
}

.attachments-widget-container-mobile > div {
    /* background-color: #f1f1f1; */
    /* width: 100px; */
    padding: 0px;
    margin: 10px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}

.art-board-cube-mobile .container {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
}

.art-board-cube-mobile .title {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #555555;
    line-height: 1.21;
    letter-spacing: normal;
    padding-bottom: 5px;
}

.art-board-cube-mobile .cube-info {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6d7278;
}

.art-board-cube-mobile .description {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    /*width: 640px;*/
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #838383;
    line-height: 1.33;
    letter-spacing: normal;
    white-space: pre-line;
}