/*html,body {*/
/*  width: 100%;*/
/*  margin: 0px;*/
/*  padding: 0px;*/
/*  overflow-x: hidden;*/
/*}*/

.app-collections-mobile {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
}

.wrapper {
}

/*slider styles*/

.app-collections-mobile.arrow {
  width: 25px;
  /*height: 18px;*/
  object-fit: contain;
  position: absolute;
}

.app-collections-mobile.next {
  right: 16px;
  cursor: pointer;
}

.app-collections-mobile.prev {
  right: 50px;
  cursor: pointer;
}

a {
  color: #ffffff;
}

a:visited {
  color: #ffffff;
}