.members-desktop {
    width: 100%;
    /*height: 315px;*/
    padding-top: 0px;
    /* padding-bottom: 25px; */
    margin: 0 auto;
    margin-bottom: 6vh;
    /* background: white; */
    /* border-bottom: 1px solid #d2d3d5; */
}

.members-desktop .members-navigation {
    padding-bottom: 15px;
    position: relative;
}

.members-desktop .members-container {
    /* margin: 0 auto; */
    /* display: flex; */
    /* justify-content: center; */
    /* width: 100%; */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    text-align: center;
    width: 100%;
    height: 56px;
}

.members-desktop .members-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    padding-top: 15px;
    padding-left: 30px;
}

.members-desktop .members-container .members-slide-title {
    margin: 0 auto;
    width: 185px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
}

.members-desktop .members-container .member-circle {
    /* display: block; */
    /* display: inline-block; */
    float: left;
    margin: 0 -15px;
    width: 56px;
    height: 56px;
    background-color: white;
    border-radius: 50%;
    border: solid 4px #ffffff;
}

.members-desktop .members-container .member-circle.last {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";

    position: relative;
    float: left;
    background-color: #0088ff;
    border: solid 4px #ffffff;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff; 
    
    display: flex;
    justify-content: center;
    align-items: center;
}

.center {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    margin: auto;  
}

.members-desktop .members-image {
    margin: 0 auto;
    width: 125px;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
}

.members-desktop .members-slide-text {
    padding-top: 15px;
    text-align: center;
}

.members-desktop .members-category {
    margin: 0 auto;
    width: 185px;
    height: 14px;
    font-size: 18px;
    line-height: 1.17;
    color: #979797;
    padding-top: 5px;
}

/* User Avatar */

.UserAvatar {
    /* display: inline-block; */
    color: white;
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    /* font-size: 16px; */
    font-weight: 550;

    /* Circle */
    float: left;
    margin: 0 -15px;
    width: 56px;
    height: 56px;
    /* background-color: white; */
    border-radius: 50%;
    border: solid 4px #ffffff;
  }
  
  .UserAvatar--inner {
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .UserAvatar--light {
    color: gray;
  }
  
  .UserAvatar--light .UserAvatar--inner {
    border: 1px solid lightgray;
  }