.yelps-nearby-desktop {
    width: 900px;
    height: 360px;
    padding-top: 25px;
    margin: 0 auto;
    background: white;
    border-bottom: 1px solid #d2d3d5;
}

.slick-prev:before,
.slick-next:before {
  color: #5B5B5B !important;
}

.yelps-nearby-desktop .yelps-navigation {
    padding-bottom: 15px;
    position: relative;
}

.yelps-nearby-desktop .yelps-nearby-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    padding-left: 20px;
}

.yelps-nearby-desktop .yelp-container {
    position: relative;
}

.yelps-nearby-desktop .yelp-image {
    margin: 0 auto;
    width: 185px;
    height: 185px;
    
	object-fit: cover;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
	background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}


.yelps-nearby-desktop .yelp-image-left {
    margin: 0 auto;
    width: 185px;
    height: 185px;
    
	object-fit: cover;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
	background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
	transform: translateX(-300px);	
}

.yelps-nearby-desktop .yelp-logo {
    width: 50px;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
}


.yelps-nearby-desktop .yelp-logo-left {
    width: 50px;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
	transform: translateX(-650%) translateY(-100%);
    -ms-transform: translateX(-650%) translateY(-100%);
}


.yelps-nearby-desktop .yelp-slide-title {
    margin: 0 auto;
    width: 185px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    padding-bottom: 3px;
}

.yelps-nearby-desktop .rating-info {
    margin: 0 auto;
    width: 185px;
}

.yelps-nearby-desktop .star-rating {
    font-size: 16px;
    line-height: 1.13;
    color: CornFlowerBlue;
}

.yelps-nearby-desktop .stars {
    width: 85px;
    padding-left: 3px;
    padding-right: 3px;
}

.yelps-nearby-desktop .num-ratings {
    font-size: 16px;
    line-height: 1.13;
    color: #979797;
}

.yelps-nearby-desktop .yelp-slide-text {
    padding-top: 15px;
}



.yelps-nearby-desktop .yelp-slide-text-left {
    padding-top: 15px;
	transform: translateX(-300px);	
}

.yelps-nearby-desktop .yelp-info {
    margin: 0 auto;
    width: 185px;
    font-size: 16px;
    line-height: 1.17;
    color: #979797;
    padding-top: 5px;
}


img {  
    position: relative;
  }
  
  /* style this to fit your needs */
  /* and remove [alt] to apply to all images*/
  img[alt]:after {  
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    font-family: 'Helvetica';
    font-weight: 300;
    line-height: 2;  
    text-align: center;
    content: attr(alt);
  }