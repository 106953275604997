.stops-nearby-desktop {
    width: 100%;
    height: 370px;
    padding-top: 25px;
    margin: 0 auto;
    background: white;
    border-bottom: 1px solid #d2d3d5;
}

.slick-prev:before,
.slick-next:before {
  color: #5B5B5B !important;
}

.stops-nearby-desktop .stops-nearby-navigation {
    padding-bottom: 15px;
    position: relative;
}

.stops-nearby-desktop .stops-nearby-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    padding-top: 15px;
    padding-left: 20px;
}

.stops-nearby-desktop .stop-slide-title {
    margin: 0 auto;
    width: 80%;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    
    /* Limit 2 lines text */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    text-align: center;
}

.stops-nearby-desktop .stop-slide-title-left {
    margin: 0 auto;
    width: 185px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
	/* transform: translateX(-300px); */
}

.stops-nearby-desktop .stop-slide-title-two{
  	transform: translateX(-170px);
}

.stops-nearby-desktop .stop-nearby-image {
    margin: 0 auto;
    width: 185px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;
}


.stops-nearby-desktop .stop-nearby-image-two {
  	transform: translateX(-170px);
}

.stops-nearby-desktop .stop-nearby-image-left {
    margin: 0 auto;
    width: 185px;
    height: 185px;
    
	object-fit: cover;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
	background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    transform: translateX(-300px);	
    border-radius: 50%;
}

.stops-nearby-desktop .stop-slide-text {
    padding-top: 15px;
    text-align: center;
}

.stops-nearby-desktop .stop-category {
    margin: 0 auto;
    width: 185px;
    height: 11px;
    font-size: 11px;
    line-height: 1.17;
    color: #979797;
    padding-top: 5px;
}

.stops-nearby-desktop .stop-category-left {
    margin: 0 auto;
    width: 185px;
    height: 11px;
    font-size: 11px;
    line-height: 1.17;
    color: #979797;
    padding-top: 5px;
	transform: translateX(-300px);
}


.stops-nearby-desktop .stop-category-two {
   	transform: translateX(-170px);
}

.stops-nearby-desktop .stop-address {
    padding-top: 3px;
    /* font-size: 10px; */
	/* text-align: center; */
    overflow: hidden;

    margin: 0 auto;
    width: 185px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #979797;
}


.stops-nearby-desktop .stop-address-left {
	
    padding-top: 3px;
    /* font-size: 10px; */
	/* text-align: center; */
    overflow: hidden;

    margin: 0 auto;
    width: 185px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #979797;
	/* transform: translateX(-300px); */

}

.stops-nearby-desktop .stop-address-two {
  	transform: translateX(-170px);
}	


