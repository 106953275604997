.join-container-desktop {
    margin-top: 5%;
    margin-bottom: 10%;
    width: 100%;

    /*
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    */


    /*
    display: table-row;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 300px; */
}

.join-container-desktop div {
    margin: 10px;
    text-align: center;
    /* vertical-align: top; */
}

.join-container-desktop span {
    text-align: center;
    vertical-align: bottom;
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b7b7b7;
}

/* Links */

.join-container-desktop span a {
    font-weight: 500;
    color: #0088ff;
    text-decoration: underline;
}

.join-container-desktop #join-btn {
    /* display: table-cell; */
    vertical-align: top;

    width: 265px;
    height: 50px;

    border-radius: 14px;
    background-color: #0088ff;
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    border: none;
    color: white;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    outline: none;
    margin: 0 auto;
    transition: 0.5s;
    text-align: center;
}

.join-container-desktop #join-btn:hover {
    cursor: pointer;
    background-color: #1c95ff;
}

.join-container-desktop #join-btn:active {
    background-color: #1985e4;
}

.join-container-desktop #join-btn:hover:focus {
    border: none;
}