.navbar-pixel-desktop {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}

.navbar-pixel-desktop .navbar-container {
    height: 60px;
    /* border-radius: 4px; */
    background-color: #0e70ec;
    position: relative;
}

.navbar-pixel-desktop .stops-logo {
    width: 97px;
    height: 36px;
    object-fit: contain;
    padding-left: 32px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.navbar-pixel-desktop .profile-pic {
    width: 30px;
    height: 30px;
    object-fit: contain;
    right: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    border-radius: 50%;
}

.navbar-pixel-desktop .navbar-text {
    width: 100px;
    color: #ffffff;
    font-size: 12px;
    text-align: right;
    right: 68px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}