.attachments-mobile {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #d2d3d5;
}

.attachments-mobile .attachments-navigation {
    padding-bottom: 15px;
    position: relative;
}

.attachments-mobile .attachments-title {
    font-size: 16px;
    font-weight: bold;
	margin: 20px;
}

.attachments-mobile .slider {

}

.attachments-desktop .attachement-link{
	text-decoration: none;
}


.attachments-mobile .attachment-item {
    
	background-color: #D3D3D3;
	
	height:88px;
	width:88px;
	display: flex;
	justify-content: center;
	align-items: center;
    
	color: #fff;
	font-size: 1em;
	font-weight: bold;
	font-family: Helvetica;
	text-align: center;

	/* text-shadow:  */
    /* 0 1px 0 #ccc,  */
    /* 0 2px 0 #c9c9c9,  */
    /* 0 3px 0 #bbb,  */
    /* 0 4px 0 #b9b9b9,  */
    /* 0 5px 0 #aaa,  */
    /* 0 6px 1px rgba(0,0,0,.1),  */
    /* 0 0 5px rgba(0,0,0,.1),  */
    /* 0 1px 3px rgba(0,0,0,.3),  */
    /* 0 3px 5px rgba(0,0,0,.2),  */
    /* 0 5px 10px rgba(0,0,0,.25),  */
    /* 0 10px 10px rgba(0,0,0,.2),  */
    /* 0 20px 20px rgba(0,0,0,.15); */
	
}

.attachments-mobile .attachment-item-left {
	transform: translate(-100%, 0%);
	
}

.attachments-mobile .attachment-item-two{
	transform: translateX(20px);
}

.attachments-mobile .attachment-item-1 {
		background-color: #D3D3D3;
	
	height:88px;
	width:88px;
	display: flex;
	justify-content: center;
	align-items: center;
    
	color: #fff;
	font-size: 1em;
	font-weight: bold;
	font-family: Helvetica;
	text-align: center;

	text-shadow: 
    0 1px 0 #ccc, 
    0 2px 0 #c9c9c9, 
    0 3px 0 #bbb, 
    0 4px 0 #b9b9b9, 
    0 5px 0 #aaa, 
    0 6px 1px rgba(0,0,0,.1), 
    0 0 5px rgba(0,0,0,.1), 
    0 1px 3px rgba(0,0,0,.3), 
    0 3px 5px rgba(0,0,0,.2), 
    0 5px 10px rgba(0,0,0,.25), 
    0 10px 10px rgba(0,0,0,.2), 
    0 20px 20px rgba(0,0,0,.15);
    transform: translate(-100%, 0%);
}


.attachments-mobile .attachment-image {
    margin: 0 auto;
    width: 90%;
    height: 115px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    cursor: pointer;
	box-shadow: 0px 6px 5px #ccc;
}

.attachments-mobile .attachment-slide-title {
    margin: 0 auto;
    width: 90%;
	height: 20px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.13;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    padding-top: 10px;
}

.attachments-mobile .attachment-slide-title-two{
	transform: translateX(10px);	
}

.attachments-mobile .attachment-slide-text {
}

.attachments-mobile .attachment-slide-text-left {
	transform: translate(-55%, 0%);
}

.attachments-mobile .file-size {
    margin: 0 auto;
    width: 90%;
    font-size: 12px;
    color: #979797;
    padding-top: 2px;
}

.attachments-mobile .file-size-two{
	transform: translateX(10px);	
}