.bottom-links-widget-mobile {
    background-color: white;
    width: 100% !important;
    margin: 0 auto;
}

.bottom-links-widget-mobile .container .logo-blue {
    width: 100px;
    height: 35px;
    object-fit: contain;
    margin: 0 auto;
}

.bottom-links-widget-mobile .container .link-container {
    padding: 20px;
    border-bottom: solid 1px #f3f3f3;
    text-align: center;
}

.bottom-links-widget-mobile .container .link-container a {
    width: 100%;
    height: 100%;
    /* font-family: MuseoSlab; */
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #383838;
    
    text-decoration: none;
}

.bottom-links-widget-mobile .container .link-container a:hover {
    color: #464646;
}

.bottom-links-widget-mobile .container .link-container a:focus {
    color: #292929;
}