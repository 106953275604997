.large-carousel-stop-mobile .carousel {
    height: 316px;
    /*object-fit: cover;*/
}

/*.carousel-item {*/
/*    height: 316px !important;*/
/*    object-fit: cover;*/
/*}*/

.large-carousel-stop-mobile .carousel-image {
    height: 316px !important;
    object-fit: cover;
}


.large-carousel-stop-mobile .watermark{
    width: 85px;
    /* box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.5); */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
	contain: content;
}

.large-carousel-stop-mobile .waterdiv{
	width: 70px;
    height: 30px;
    margin: 0 auto;
    position: absolute;
    right: 0%;
    bottom: 0%;
    /* transform: translateX(-50%) translateY(-50%); */
}


.large-carousel-stop-mobile .bubble-gum {
    width: 40px;
    height: 40px;
    position: absolute;
    background-image: url('/img/bubble.png');
    background-repeat: no-repeat;

}


.large-carousel-stop-mobile .stops-video {
    /* width: 100%; */
    height: 360px;
    padding-top: 55px;
    padding-bottom: 55px;
    background-color: black;
}
@media screen and (max-width: 770px){
.video-mobile{
	width: 760px;
    height: 434px;
	}
}


@media screen and (max-width: 750px){
    .video-mobile {
        width: 730px;
        height: 417px;
        
    }
}

@media screen and (max-width: 700px){
    .video-mobile {
        width: 700px;
        height: 400px;
        
    }
}

@media screen and (max-width: 650px){
    .video-mobile {
        width: 650px;
        height: 371px;
        
    }
}

@media screen and (max-width: 600px){
    .video-mobile {
        width: 600px;
        height: 342px;
        
    }
}

@media screen and (max-width: 550px){
    .video-mobile {
        width: 550px;
        height: 314px;
        
    }
}



@media screen and (max-width: 500px){
    .video-mobile {
        width: 500px;
        height: 285px;
        
    }
}

@media screen and (max-width: 450px){
    .video-mobile {
        width: 450px;
        height: 257px;
        
    }
}



@media screen and (max-width: 415px){
    .video-mobile {
        width: 410px;
        height: 234px;
        
    }
}


@media screen and (max-width: 412px){
    .video-mobile {
        width: 410px;
        height: 234px;
        
    }
}


@media screen and (max-width: 376px){
    .video-mobile {
        width: 375px;
        height: 214px;
        
    }
}

@media screen and (max-width: 361px){
    .video-mobile {
		width: 360px;
        height: 205px;
       
    }
}

@media screen and (max-width: 321px){
    .video-mobile {
		width: 310px;
        height: 177px;
		
        
    }
}



