.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    object-fit: contain;
    background: #5cabff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    left: 50%;
    right: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    /* CAN BE USED! */
    /* background: radial-gradient(circle at 10px 10px, #5cabff, #000); */
  }

  .circle-enumerated {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    object-fit: contain;
    background: #5cabff;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0;
    left: 3%;
    right: 0;
    top: 100%;
    -ms-transform: translateY(140%);
    transform: translateY(130%);

    text-align: center;
    vertical-align: middle;
    line-height: 16px;
  }
  
  .stops-watermark {
    width: 40%;
    height: 40%;
    /* 
    object-fit: contain;
    display: block; */
    /* display: block;
    margin-left: auto;
    margin-right: auto */
    /* background: url(../../../../img/stops_icon_watermark.png) no-repeat;  */
    
    /* background-image: url('/img/stops_icon_watermark.png'); */
    /* background-repeat: no-repeat; */
    /* z-index: 999999; */
  }

  /* Shown in left side as enumerated number */
  .stops-watermark-text {
    width: 40%;
    height: 40%;
    font-size: 14pt;
  }