.footer-mobile {
    height: 398px;
    background-color: #167efb;
    color: white;
    padding-bottom: 70px;
}

.footer-mobile .footer-text {
    font-size: 14px;
    line-height: 1.86;
    color: #ffffff;
    padding-left: 16px;
}

.footer-mobile .footer-logo {
    padding-top: 44px;
    width: 32px;
    height: 32px;
    object-fit: contain;
    padding-bottom: 10px;
}

.footer-mobile .stops-link-text {
    padding-bottom: 5px;
}

.footer-mobile .blog-link-text {
    padding-bottom: 5px;
}

.footer-mobile .help-link-text {
    padding-bottom: 24px;
}

.footer-mobile .social-media-title {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.86;
    color: #ffffff;
    padding-bottom: 16px;
}

.footer-mobile .social-media-table {
    padding-left: -3px;
}

.footer-mobile .social-media-icon {
    padding-right: 20px;
    height: 24px;
    width: 24px;
}

.footer-mobile .legal {
    padding-top: 20px;
}

.footer-mobile .center-column {
    padding-left: 30px;
    padding-right: 130px;
}