body {
  height: 100%;
  /* background-color: red; */
  background-color: #F2F2F2;
  /* position: fixed; /*<--- this is what made the difference and scaled to 100% correctly */
}

/* LOADER */
.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-overlay .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-overlay .lds-ripple div {
  position: absolute;
  border: 4px solid #0B7FFD;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader-overlay .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* END LOADER */

.app-cube-desktop {
  /*background-repeat: no-repeat;*/
  font-family: 'Open Sans', sans-serif;
}

.app-cube-desktop .wrapper {
  /* margin-top: 100px; */
}

.app-cube-desktop .outer {
  /*background-image: url("stops-city-background.jpg");*/
  /* position: relative;
  height: 100%; */
}

.app-cube-desktop .inner {
  /*background: rgba(255,255,255,0.8);*/
  /*background: rgba(59,124,242,0.7);*/
  /* background-color: #F2F2F2; */
}

.app-cube-desktop .top-components {}

.app-cube-desktop .bottom-components {
  width: 900px;
  margin: 0 auto;
  margin-top: 3%;
  border-radius: 11px;
  border: solid 1px #E5E5E5;
  background-color: #ffffff;

  /*border-radius: 15px;*/
  /*padding-top: 30px;*/
  /*
  border-left: 1px solid #d2d3d5;
  border-right: 1px solid #d2d3d5;
  */
}

/*.app-cube-desktop .box-shadow {*/
/*  !*box-shadow: 5px 5px 10px*!*/
/*}*/

.app-cube-desktop .show-background-color {
  /*background: rgba(59,124,242,1);*/
  background-color: white;
  padding-bottom: 30px;
}

/*slider styles*/

.app-cube-desktop .arrow {
  width: 30px;
  height: 18px;
  object-fit: contain;
  position: absolute;
}

.app-cube-desktop .next {
  right: 30px;
  cursor: pointer;
}

.app-cube-desktop .prev {
  right: 74px;
  cursor: pointer;
}

a {
  color: #ffffff;
}

a:visited {
  color: #ffffff;
}