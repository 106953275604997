.large-carousel-cube-desktop .cube-photo {
    display: block;
    width: 118px;
    height: 118px;
    margin: 0 auto;
    margin-top: 8%;
    /* clip-path: polygon(0 0, 100% 0, 100% 20%, 100% 80%, 80% 100%, 0 100%, 0% 80%, 0% 20%); */
    /* clip-path: path('M7,0 L91.3827751,0 C95.2487684,-1.59834986e-15 98.3827751,3.13400675 98.3827751,7 L98.3827751,69.5078595 C98.3827751,71.3014654 97.6942876,73.0266423 96.4593753,74.3274166 L75.6920503,96.2023322 C74.3703986,97.5944721 72.5350377,98.3827751 70.6154501,98.3827751 L7,98.3827751 C3.13400675,98.3827751 4.0261613e-15,95.2487684 0,91.3827751 L0,7 C-1.36162605e-15,3.13400675 3.13400675,-1.78006981e-16 7,0 Z'); */
    border: solid 1px #f3f3f3;
    background-color: #FCFCFC;
    border-radius: 14px;
    /* background-color: red; */
    /*object-fit: cover;*/
    object-fit: cover;
}

.large-carousel-cube-desktop .mask-cube-image {
    mask-image: url(./cube-image_mask_w_border.svg);
    /* mask-border: url(./cube-image_mask_border.svg); */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    mask-size: 100%;
    mask-repeat: no-repeat;
    mask-position: center;
}

/*.carousel-item {*/
/*    height: 316px !important;*/
/*    object-fit: cover;*/
/*}*/

/*.large-carousel-cube-desktop .cube-photo {*/
/*    height: 250px !important;*/
/*    object-fit: cover;*/
/*}*/