.stops-nearby-mobile {
    width: 100%;
    left: 0px;
    /* padding-left: 16px; */
    /* padding-right: 16px; */
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
}

.stops-nearby-mobile .stops-nearby-navigation {
    padding-bottom: 15px;
    position: relative;
}

.stops-nearby-mobile .stops-nearby-title {
    font-size: 16px;
    font-weight: bold;
	margin-left: 20px;
}

.stops-nearby-mobile .stop-slide-title {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
    /* Limit text for one line only */
    /* white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis; */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    text-align: center;
}


.stops-nearby-mobile .stop-address {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #979797;
    text-align: center !important;
}


.stops-nearby-mobile .stop-address-left {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #979797;
	transform: translateX(-110px);
}

.stops-nearby-mobile .stop-slide-title-left {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
	transform: translateX(-110px);
}

.stops-nearby-mobile .stop-nearby-image {
    margin: 0 auto;
    width: 80%;
    object-fit: contain;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;	
	box-shadow: 0px 6px 5px #ccc;
}

.stops-nearby-mobile .stop-nearby-image-two{
	transform: translateX(-50px);	
}

.stops-nearby-mobile .stop-nearby-image-left {
    margin: 0 auto;
    width: 80%;
    object-fit: contain;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;	
	box-shadow: 0px 6px 5px #ccc;
	transform: translateX(-110px);
}


.stops-nearby-mobile .stop-slide-text {
    padding-top: 15px;
}

.stops-nearby-mobile .stop-slide-text-left {
    padding-top: 15px;
}

.stops-nearby-mobile .stop-slide-text-two {
	transform: translateX(-60px);
}

.stops-nearby-mobile .stop-category {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    color: #979797;
    padding-top: 5px;
}

.stops-nearby-mobile .stop-category-left {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    color: #979797;
    padding-top: 5px;
	transform: translateX(-110px);
}