/*html,body {*/
/*  width: 100%;*/
/*  margin: 0px;*/
/*  padding: 0px;*/
/*  overflow-x: hidden;*/
/*}*/

.app-stop-load {
  text-align: center;
}

.app-stop-mobile {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
}

.app-stop-mobile .wrapper {
}

/*slider styles*/

.app-stop-mobile .arrow {
  width: 25px;
  /*height: 18px;*/
  object-fit: contain;
  position: absolute;
}

.app-stop-mobile .next {
  right: 0px;
  cursor: pointer;
}

.app-stop-mobile .prev {
  right: 35px;
  cursor: pointer;
}

a {
  color: #ffffff;
}

a:visited {
  color: #ffffff;
}

/* LOADER */
.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-overlay .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-overlay .lds-ripple div {
  position: absolute;
  border: 4px solid #0B7FFD;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader-overlay .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* END LOADER */