.footer-desktop {
    width: 900px;
    height: 216px;
    background-color: #167efb;
    color: white;
    padding-top: 44px;
    margin: 0 auto;
    /*border-bottom-left-radius: 12px;*/
    /*border-bottom-right-radius: 12px;*/
    background-size: 100%;
}

.footer-desktop .footer-table {
    font-size: 14px;
    line-height: 1.86;
    color: #ffffff;
    /*margin-left: 30px;*/
    margin: 0 auto;
}

.footer-desktop .footer-logo {
    width: 32px;
    height: 32px;
    object-fit: contain;
}

.footer-desktop .titles {
    padding-bottom: 10px;
}

.footer-desktop .social-media {
    position: relative;
}

.footer-desktop .social-media-table {
    position: absolute;
    bottom: -20px;
    right: 2px;
}

.footer-desktop .social-media-icon {
    padding-right: 20px;
}

.footer-desktop .legal {
    padding-top: 20px;
}

.footer-desktop .center-column {
    padding-left: 145px;
    padding-right: 220px;
}