.location-info-stop-desktop {
    background-color: white;
    width: 900px;
    margin: 0 auto;
}

.location-info-stop-desktop .location {
    height: 80px;
    object-fit: contain;
    position: relative;
    border-bottom: 1px solid #f1f1f1;
}

.location-info-stop-desktop .pin-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-stop-desktop .location-row {
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2c2c2c;
    padding-left: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-stop-desktop .location-text {
    /* position: relative; */
    padding-left: 35px;
    /* left: 35px;  */
    vertical-align: middle;
    margin: 0;
    top: 50%;
    transform: translate(-50%, -50%);
}

.location-info-stop-desktop .location-link {
    color: black;
}

.location-info-stop-desktop .altitude {
    height: 55px;
    object-fit: contain;
    position: relative;
    border-bottom: 1px solid #f1f1f1;
}

.location-info-stop-desktop .altitude-icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-stop-desktop .altitude-row {
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2c2c2c;
    padding-left: 30px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
}

.location-info-stop-desktop .altitude-text {
    padding-left: 45px;
}

.location-info-stop-desktop .sea-level {
    color: #b2b2b2;
}