.art-board-cube-mobile {
    background-color: white;
    width: 90% !important;
    margin: 0 auto;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.art-board-cube-mobile .container {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 20px;
}

.art-board-cube-mobile .title {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #555555;
    line-height: 1.21;
    letter-spacing: normal;
    padding-bottom: 5px;
    width: 90%;
}

.art-board-cube-mobile .cube-info {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    height: 24px;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6d7278;
}

.art-board-cube-mobile .description {
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    width: 90%;
    /*width: 640px;*/
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #838383;
    line-height: 1.33;
    letter-spacing: normal;
    white-space: pre-line;
}