.travel-dashboard-desktop {
    background-color: white;
    width: 900px;
    padding-top: 20px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.travel-dashboard-desktop .travel-info {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-bottom: 15px;
    line-height: 1.5;
}

.travel-dashboard-desktop .dashboard-title {
    font-size: 28px;
    font-weight: bold;
}

.travel-dashboard-desktop .number-stops {
    font-size: 18px;
    color: #0e70ec;
}

.travel-dashboard-desktop .distance-time {
    font-size: 18px;
    color: #6d7278;
    padding-left: 16px;
}

.travel-dashboard-desktop .travel-map {
    width: 100%;
}

.travel-dashboard-desktop .photo-info{
    text-align: center;
    font-size: 18px;
    color: #6d7278;
}