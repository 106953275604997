.bottom-social-links-mobile {
    /* background-color: white; */
    /* background-color: red; */
    background-color: #F2F2F2;
    width: 100% !important;
    margin: 0 auto;
    /* margin-top: 100px; */
    padding-top: 50px;
}

.bottom-social-links-mobile .container .social-links {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottom-social-links-mobile .container .social-links a {
    /* margin-right: 25px; */
    /* padding-left: 10px; */
    /* padding-right: 20px; */
    margin-right: 30px;
    /* background-color: red; */
    /* margin: 0 auto; */
    /* float: left; */
}

.bottom-social-links-mobile .container .social-links a:hover {
    opacity: 0.7;
}

.bottom-social-links-mobile .container .social-links a img {
    height: 20px;
    object-fit: contain;
}

.copyright-container {
    position: absolute;
    width: 100%;
    font-family: "Helvetica Neue", "Roboto", "sans-serif";
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #7a7a7a;
}

.copyright-container a {
    color: #0088ff;
}

.copyright-container a:hover {
    color: #269aff;
}

.copyright-container a:focus {
    color: #017ae4;
}