.google-articles-nearby-mobile {
    width: 100%;
    /* padding-left: 16px; */
    /* padding-right: 16px; */
	padding-top: 20px; 
    /* padding-bottom: 20px; */
    border-bottom: 1px solid #f1f1f1;
}

.google-articles-nearby-mobile .google-navigation {
    padding-bottom: 15px;
    position: relative;
}

.google-articles-nearby-mobile .google-title {
	padding-top: 20px; 
    font-size: 16px;
    font-weight: bold;
    margin-left: 20px;	
    
}

.google-articles-nearby-mobile .google-slide-title {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.46px;
    color: #2c2c2c;
	text-align: center;
	padding-top: 10px;
}


.google-articles-nearby-mobile .google-image {
	margin: 0 auto;
    width: 87px;
    height: 87px;
    object-fit: fill;
    border-radius: 4px;
    background-color: #f0f1f3;
    cursor: pointer;
    box-shadow: 0px 6px 5px #ccc;
	border-radius: 50%;
	
 
}

.google-articles-nearby-mobile .google-image-left {
	margin: 0 auto;
    width: 87px;
    height: 87px;
    object-fit: fill;
    border-radius: 4px;
    background-color: #f0f1f3;
    cursor: pointer;
    box-shadow: 0px 6px 5px #ccc;
	border-radius: 50%;
	transform: translateX(-115px);
	
 
}

.google-articles-nearby-mobile .google-logo {
    /* position: relative; */
    /* left: 15%; */
    /* top: 40%; */
    /* transform: translateX(100%) translateY(-160%); */
    /* -ms-transform: translateX(100%) translateY(-160%); */
	width: 60px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-125%);
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
	transform: translateX(-70px);
}

.google-articles-nearby-mobile .google-logo-left {
    /* position: relative; */
    /* left: 15%; */
    /* top: 40%; */
    /* transform: translateX(100%) translateY(-160%); */
    /* -ms-transform: translateX(100%) translateY(-160%); */
	width: 60px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-125%);
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
	transform: translateX(-70px);
}

@media screen and (max-width: 414px){
    .google-articles-nearby-mobile .google-image {
		width: 102px;
		height: 102px;
        
    }
	.google-articles-nearby-mobile .google-image-left {
		width: 102px;
		height: 102px;
        
    }
.google-articles-nearby-mobile .google-logo {
    transform: translateX(-50%) translateY(-130%);	
	}	
.google-articles-nearby-mobile .google-logo-left {
    transform: translateX(-50%) translateY(-130%);	
	}		
}


@media screen and (max-width: 375px){
    .google-articles-nearby-mobile .google-image {
		width: 91px;
		height: 91px;
        
    }
	.google-articles-nearby-mobile .google-image-left {
		width: 91px;
		height: 91px;
        
    }
	.google-articles-nearby-mobile .google-logo {
    transform: translateX(-50%) translateY(-130%);	
	}
	.google-articles-nearby-mobile .google-logo-left {
    transform: translateX(-240%) translateY(-130%);	
	}		
}

@media screen and (max-width: 320px){
    .google-articles-nearby-mobile .google-image {
		width: 77px;
		height: 77px;
        
    }
    .google-articles-nearby-mobile .google-image-left {
		width: 77px;
		height: 77px;
        
    }	
.google-articles-nearby-mobile .google-logo {
	width: 40px;
    transform: translateX(-50%) translateY(-145%);	
	}
.google-articles-nearby-mobile .google-logo-left {
	width: 40px;
    transform: translateX(-50%) translateY(-145%);	
	}	
}

.google-articles-nearby-mobile .google-image:focus{
	margin: 0 auto;
    width: 90%;
    height: 115px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #f0f1f3;
    cursor: pointer;	
}

.google-articles-nearby-mobile .google-slide-text {
    padding-bottom: 15px; 
	/* transform: translateX(10%) translateY(-120%); */
	transform: translateY(-100%); 
}

.google-articles-nearby-mobile .google-slide-text-left {
    padding-bottom: 15px; 
	/* transform: translateX(10%) translateY(-120%); */
	transform: translateY(-100%) translateX(-55%); 
}


.google-articles-nearby-mobile .google-category {
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    color: #979797;
    padding-top: 5px;
}


.google-articles-nearby-mobile .box-1 {
    position: relative;
    width:    50%; /* desired width */
}

.google-articles-nearby-mobile .box-1:before {
    content:     "";
    display:     block;
    padding-top: 100%; /* initial ratio of 1:1*/
}

.google-articles-nearby-mobile .content-1 {
    position: absolute;
    top:      0;
    left:     0;
    bottom:   0;
    right:    0;
}

.google-articles-nearby-mobile .content-left {
    position: absolute;
    top:      0;
    left:     10;
    bottom:   0;
    right:    0;
}