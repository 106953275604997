.app-links {
    height: 88px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #f9f9f9;
    /*background-color: whitesmoke;*/
    /*background: rgba(255,255,255,0.8);*/
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    z-index: 100;
    -webkit-transition: 0.75s;
    -moz-transition: 0.75s;
    -o-transition: 0.75s;
    transition: 0.75s;
    opacity: 1;
    visibility: visible;
    box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28)
}

/*.app-links.hide {*/
/*    opacity: 0;*/
/*    visibility: hidden;*/
/*}*/

.app-link-container {
    position: relative;
}

.download-stops {
    width: 287px;
    height: 27px;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #167efb;
    position: absolute;
    margin: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 30px;
}

.google-play {
    width: 162px;
    height: 48px;
    object-fit: contain;
    position: absolute;
    top: 20px;
    left: 208px;
}

.app-store {
    width: 162px;
    height: 48px;
    object-fit: contain;
    position: absolute;
    top: 20px;
    left: 380px;
}