@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    src: url('./SF-Pro-Display-Medium.otf');
}

.weather-mobile {
    /*width: 700px;*/
    height: 175px;
    border-bottom: 1px solid #f1f1f1;
    padding-left: 16px;
    padding-bottom: 10px;
}

.weather-mobile .weather-info {
    padding-left: 16px;
    padding-top: 15px;
}

.weather-mobile .weather-title {
    width: 86px;
    height: 28px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.4;
}

.weather-mobile .weather-description {
    position: relative;
    padding-top: 15px;
}

/*.description-text-wrapper {*/
/*}*/

.weather-mobile .now {
    width: 40px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.44px;
    margin-bottom: 10px;
}

.weather-mobile .description-text {
    width: 80px;
    font-size: 12px;
    line-height: 1.17;
    letter-spacing: 0.35px;
    top: 50%;
    /* margin-bottom: 10px; */
}

.weather-mobile .weather-icon {
    width: 49px;
    height: 47px;
    object-fit: contain;
    left: 90px;
    bottom: -10px;
    /*top: 50%;*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    position: absolute;
}

.weather-mobile .temperature {
    position: relative;
}

.weather-mobile .temperature-value {
    font-family: "SF-Pro";
    width: 67px;
    height: 43px;
    font-size: 26px;
    /*font-weight: 500;*/
    letter-spacing: -1.16px;
    text-align: center;
    left: 124px;
    bottom: -13px;
    /*top: 50%;*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    position: absolute;
}

.weather-mobile .temperature-value-f {
    font-family: "SF-Pro";
    width: 100px;
    height: 43px;
    font-size: 26px;
    /*font-weight: 500;*/
    letter-spacing: -1.16px;
    text-align: center;
    left: 204px;
    bottom: -13px;
    /*top: 50%;*/
    /*-ms-transform: translateY(-50%);*/
    /*transform: translateY(-50%);*/
    position: absolute;
}

.weather-mobile .degrees {
    width: 29px;
    height: 18px;
    object-fit: contain;
    position: absolute;
    left: 180px;
    bottom: 10px;
}

.weather-mobile .degrees-f {
    width: 29px;
    height: 18px;
    object-fit: contain;
    position: absolute;
    left: 296px;
    bottom: 10px;
}