@font-face {
    font-family: 'SF-Pro';
    font-style: normal;
    src: url('./SF-Pro-Display-Medium.otf');
}

.pin-info-mobile {
    /* height: 695px; */
}

.pin-info-mobile .pins-container {
    font-size: 12px;
}

.pin-info-mobile .pin-outer {
    /*padding-bottom: 10px;*/
}

.pin-info-mobile .pin-1 {
    /* height: 178px; */
}

.pin-info-mobile .pin-2 {
    /* height: 158px; */
}

.pin-info-mobile .pin-3 {
    /* height: 178px; */
}

.pin-info-mobile .pin-4 {
    /* height: 178px; */
}


.pin-info-mobile .pin-header {
    position: relative;
}

.pin-info-mobile .pin-icon {
    width: 30px;
    /* height: 30px; */
    position: absolute;
    top: 28px;
    left: 16px;
}

.pin-info-mobile .pin-num {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    top: 33px;
    left: 23px;
}



.pin-info-mobile .pin-num-1 {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: #ffffff;
    position: absolute;
    top: 33px;
    left: 27px;
}

.pin-info-mobile .location-pic {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 16px;
    left: 53px;
	object-fit: cover;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.pin-info-mobile .pin-header-info {
    position: absolute;
    top: 16px;
    left: 120px;
    line-height: 1.2;

}

.pin-info-mobile .pin-name {
    font-size: 16px;
    line-height: 1.38;
    font-weight: bold;
    padding-bottom: 3px;
}

.pin-info-mobile .more-information {
    font-family: "SF-Pro";
    font-size: 12px;
    line-height: 1.17;
    color: #a9abae;
    padding-bottom: 2px;
}

.pin-info-mobile .pin-address {
    font-family: "SF-Pro";
    font-size: 12px;
    line-height: 1.17;
    color: #a9abae;
}

.pin-info-mobile .stars-table {
}

.pin-info-mobile .star-num {
    font-size: 12px;
    line-height: 1.17;
    color: #0b7ffd;
    padding-right: 2px;
}

.pin-info-mobile .cell-width {
    width: 12px;
}

.pin-info-mobile .blue-stars {
    width: 15px;
}

.pin-info-mobile .num-ratings {
    font-size: 12px;
    line-height: 1.17;
    color: #b2b2b2;
}

.pin-info-mobile .yelp-stars {
    width: 74.8px;
    height: 14px;
}

.pin-info-mobile .num-reviews {
    font-family: "SF-Pro";
    font-size: 12px;
    line-height: 1.17;
    color: #a9abae;
    padding-left: 5px;
}

.pin-info-mobile .pin-description {
    width: 90%;
    /* height: 80px; */
    font-size: 14px;
    line-height: 1.43;
    color: #2c2c2c;
    padding-top: 92px;
    padding-left: 16px;
	margin: 10px 0px 6px 7px;
}

.pin-info-mobile .pin-container {
    object-fit: contain;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #e5e5e5;
}